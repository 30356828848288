#root {
  background-color: silver;
  width: fit-content;
  padding: 1rem;
  border-radius: 5px;
  max-width: 500px;
  margin: auto;
}
label {
  display: block;
  font-weight: bold;
}

input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

p{
  text-align: right;
}
